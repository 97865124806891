var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "outer" },
    [
      _c(
        "breadcrumb-header",
        { attrs: { labels: ["New User"], go_back_to: "management_users" } },
        [
          _vm.result
            ? [
                _vm.result.password
                  ? _c(
                      "el-popconfirm",
                      {
                        attrs: {
                          title: "Make sure you have copied the password!",
                          "confirm-button-text": "OK",
                          "cancel-button-text": "Go Back",
                        },
                        on: { confirm: _vm.reset },
                      },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { slot: "reference", size: "mini" },
                            slot: "reference",
                          },
                          [_vm._v(" Create Another User ")]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ]
            : _vm._e(),
        ],
        2
      ),
      _vm.result
        ? [
            _c(
              "el-form",
              {
                attrs: { "label-width": "160px" },
                nativeOn: {
                  submit: function ($event) {
                    $event.preventDefault()
                  },
                },
              },
              [
                _c(
                  "el-form-item",
                  { attrs: { label: "Name" } },
                  [
                    _c(
                      "el-input",
                      {
                        attrs: { readonly: true },
                        model: {
                          value: _vm.result.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.result, "name", $$v)
                          },
                          expression: "result.name",
                        },
                      },
                      [
                        _c("el-button", {
                          directives: [
                            {
                              name: "clipboard",
                              rawName: "v-clipboard:copy",
                              value: _vm.result.name,
                              expression: "result.name",
                              arg: "copy",
                            },
                          ],
                          staticClass: "copy-button",
                          attrs: {
                            slot: "append",
                            size: "small",
                            icon: "el-icon-document-copy",
                          },
                          nativeOn: {
                            click: function ($event) {
                              return _vm.showClipboardNotification("Name")
                            },
                          },
                          slot: "append",
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "Email" } },
                  [
                    _c(
                      "el-input",
                      {
                        attrs: { readonly: true },
                        model: {
                          value: _vm.result.email,
                          callback: function ($$v) {
                            _vm.$set(_vm.result, "email", $$v)
                          },
                          expression: "result.email",
                        },
                      },
                      [
                        _c("el-button", {
                          directives: [
                            {
                              name: "clipboard",
                              rawName: "v-clipboard:copy",
                              value: _vm.result.email,
                              expression: "result.email",
                              arg: "copy",
                            },
                          ],
                          staticClass: "copy-button",
                          attrs: {
                            slot: "append",
                            size: "small",
                            icon: "el-icon-document-copy",
                          },
                          nativeOn: {
                            click: function ($event) {
                              return _vm.showClipboardNotification("Email")
                            },
                          },
                          slot: "append",
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm.result.password
                  ? _c(
                      "el-form-item",
                      { attrs: { label: "Password" } },
                      [
                        _c(
                          "el-input",
                          {
                            attrs: {
                              type: "password",
                              readonly: true,
                              "show-password": "",
                            },
                            model: {
                              value: _vm.result.password,
                              callback: function ($$v) {
                                _vm.$set(_vm.result, "password", $$v)
                              },
                              expression: "result.password",
                            },
                          },
                          [
                            _c("el-button", {
                              directives: [
                                {
                                  name: "clipboard",
                                  rawName: "v-clipboard:copy",
                                  value: _vm.result.password,
                                  expression: "result.password",
                                  arg: "copy",
                                },
                              ],
                              staticClass: "copy-button",
                              attrs: {
                                slot: "append",
                                size: "small",
                                icon: "el-icon-document-copy",
                              },
                              nativeOn: {
                                click: function ($event) {
                                  return _vm.showClipboardNotification(
                                    "Password"
                                  )
                                },
                              },
                              slot: "append",
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              1
            ),
          ]
        : _c(
            "el-form",
            {
              ref: "form",
              staticClass: "form-wrapper",
              attrs: {
                "auto-complete": "on",
                model: _vm.form,
                rules: _vm.rules,
                "label-width": "200px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "Name", prop: "name" } },
                [
                  _c("el-input", {
                    attrs: {
                      name: "name",
                      type: "text",
                      "auto-complete": "off",
                    },
                    model: {
                      value: _vm.form.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "name", $$v)
                      },
                      expression: "form.name",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "Email", prop: "email" } },
                [
                  _c("el-input", {
                    attrs: {
                      name: "email",
                      type: "email",
                      "auto-complete": "off",
                      placeholder: "user@email.com.au",
                    },
                    model: {
                      value: _vm.form.email,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "email", $$v)
                      },
                      expression: "form.email",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "Role", prop: "role" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        placeholder: "User Role",
                        "value-key": "role",
                        name: "role",
                      },
                      model: {
                        value: _vm.form.role,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "role", $$v)
                        },
                        expression: "form.role",
                      },
                    },
                    _vm._l(_vm.roles, function (r) {
                      return _c("el-option", {
                        key: r.name,
                        attrs: { label: r.name, value: r.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-button",
                {
                  staticClass: "create-button",
                  attrs: { disabled: _vm.creating },
                  on: { click: _vm.handleSubmit },
                },
                [_vm._v(" " + _vm._s("Create") + " ")]
              ),
            ],
            1
          ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }